<template lang="pug">
button.base-button(
  :class='{ "base-button_outlined": outlined, "base-button_block": block }'
  :style='{ "--btn-color": color }'
)
  v-icon.base-button__icon(v-if='icon' :icon='icon' :size='size')
  slot
</template>

<script>
export default {
  name: 'VButton',
  props: {
    icon: { type: String, default: '' },
    size: { type: String, default: '' },
    outlined: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    color: { type: String, default: '' },
  },
}
</script>

<style lang="scss">
.base-button {
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  user-select: none;
  background-color: var(--btn-color, $primary-color);
  color: #ffffff;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    box-shadow: 0 8px 25px -8px var(--btn-color, $primary-color);
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;

    &:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &__icon {
    color: #ffffff;
    margin: 0 10px;
    margin-left: 0;
  }

  &_outlined {
    background: inherit;
    color: var(--btn-color, $primary-color);
    border: 1px solid var(--btn-color, $primary-color);

    & .base-button__icon {
      color: var(--btn-color, $primary-color);
    }
  }

  &_block {
    width: 100%;
    justify-content: center;
  }
}
</style>
